<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouveau style" :active.sync="popupStyle">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-select label="Abreg Categ*" autocomplete class="selectExample w-full select-large" v-validate="'required'" name="AbregCateg" v-model="AbregCateg">
                <vs-select-item :key="index" :value="item.Abreg_Categ" :text="item.Abreg_Categ" v-for="(item,index) in Categorie_articleData" />
              </vs-select>
              <span class="text-danger text-sm"  v-show="errors.has('AbregCateg')">Le champs Abreg Categ est requis</span>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle style*" v-validate="'required'" name="StyleAbreg" v-model="StyleAbreg" />
              <span class="text-danger text-sm"  v-show="errors.has('StyleAbreg')">Le champs libelle style est requis</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="createStyle" :disabled="validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="AbregCateg = StyleAbreg = ''; ">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier le style" :active.sync="popupStyleUpdate">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-select label="Abreg Categ*" autocomplete class="selectExample w-full select-large" name="AbregCateg" v-model="AbregCateg">
                <vs-select-item :key="index" :value="item.Abreg_Categ" :text="item.Abreg_Categ" v-for="(item,index) in Categorie_articleData" />
              </vs-select>
              <span class="text-danger text-sm"  v-show="errors.has('AbregCateg')">Le champs Abreg Categ est requis</span>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle style*" v-validate="'required'" name="StyleAbreg" v-model="StyleAbreg" />
              <span class="text-danger text-sm"  v-show="errors.has('StyleAbreg')">Le champs libelle style est requis</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="updateStyle" :disabled="!validateForm">Modifier</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="AbregCateg = StyleAbreg = null;  popupStyleUpdate = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table noDataText="" v-model="selected" pagination max-items="10" search :data="styleData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown> -->
                <vs-button class="mb-4 md:mb-0" @click="popupStyle=true">Nouveau Style</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="index">
                  ##
                </vs-th>
                <vs-th sort-key="AbregCateg">
                  Abreg Categorie
                </vs-th>
                <vs-th sort-key="monnaie">
                  Libelle style
                </vs-th>
                <vs-th>
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].AbregCateg">
                    {{data[indextr].AbregCateg}}
                  </vs-td>

                  <vs-td :data="data[indextr].StyleAbreg">
                    {{data[indextr].StyleAbreg}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateStyleShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupStyle: false,
      popupStyleUpdate: false,
      // status: false,
      AbregCateg: null,
      StyleAbreg: null,
      selected:[],
      styles:[],
      styleId: null
    }
  },
  computed: {
    validateForm () {
      return this.errors.any() && (this.AbregCateg !== '' || this.AbregCateg !== null || this.AbregCateg !== undefined)  && (this.StyleAbreg !== '' || this.StyleAbreg !== null || this.StyleAbreg !== undefined)
    },
    styleData () {
      return this.$store.state.style.styles
    },
    Categorie_articleData () {
      return this.$store.state.categorie_article.categorie_articles
    }
  },

  methods: {
    createStyle () {
      const payload = {
        AbregCateg: this.AbregCateg,
        StyleAbreg: this.StyleAbreg
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('style/createStyle', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupStyle = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupStyle = false
        })
    },
    confirmDeleteRecord (data) {
      this.styleId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le type "${data.StyleAbreg}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('style/removeStyle', this.styleId)
        .then(() => {
          this.styleId = null
          this.showDeleteSuccess()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Supprimer',
        text: 'Le type selectionné a bien été supprimée'
      })
    },
    getStyles () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('style/getStyles')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
        })
        .finally(() => {
          
        })

    },
    updateStyleShowForm (data) {

      this.AbregCateg = data.AbregCateg
      this.StyleAbreg = data.StyleAbreg
      this.styleId = data._id
      this.popupStyleUpdate = true
    },
    updateStyle () {
      const payload = {
        AbregCateg: this.AbregCateg,
        StyleAbreg: this.StyleAbreg,
        id: this.styleId
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('style/updateStyle', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupStyleUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupStyleUpdate = false
        })
    },
    resete_data () {
      this.AbregCateg = this.StyleAbreg = ''
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })

    }
  },
  created () {
    this.getStyles()
    this.getCategorieArticles()
  }
}
</script>

